import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Message from '../Message/Message';
import style from './Chat.module.css';

import { FiSend } from 'react-icons/fi';
import CircularProgress from '@mui/material/CircularProgress';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import { Typography as TypographyComponent } from '@mui/material';

const ChatField = (props) => {
    const { 
        messages, 
        setMessages, 
        submitMessage, 
        myId, 
        kakaoNick, 
        isGeneratingAnswer,
        fetchChatHistory,
        updateProductHistory,
        getChatbotReply,
        chatData,
        resetQueryCondition
    } = props;
	
	const navigate = useNavigate();
	const { roomId, userId } = useParams();
	
    const [isMobileDevice, setIsMobileDevice] = useState(false);
	const isExpert = (kakaoNick === "guest");
    //COMM: Check whether the device is mobile or not
    useEffect(() => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        setIsMobileDevice(isMobile);
    }, []);

    // API request for query condition at server
    useEffect(() => {
        resetQueryCondition(roomId);
    }, [])

    const messagesEndRef = useRef(null);
    const inputRef = useRef(null);
    const [input, setInput] = useState('');

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (isGeneratingAnswer) {
                return;
            }
            onClick();
        }
    };

    const onClick = async () => {
        if (isGeneratingAnswer || kakaoNick === '') return;
        
        // console.log('onclick input: ', {userId: userId, message: input});
        let message = [{userId: userId, message: input}];
        setMessages((prev) => prev.concat(message));
        setInput('');
        const chatbotReply = await getChatbotReply(input, roomId, userId);
        setMessages((prev) => prev.concat({userId: 'Accio', message: chatbotReply}));
        // console.log('onclick message: ', messages);
        
    }


    const onChange = (e) => {
        setInput(e.currentTarget.value);
    };

    //COMM: Auto scroll to bottom when new message is present
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
    };

    useEffect(() => {
        if (messages.length === 1) {
            document.getElementById('ChatField').scrollTo(0, 0);
        } else {
            scrollToBottom();
        }
    }, [messages]);

    const handleButtonClick_fill = (text) => {
        setInput(text);
        inputRef.current.focus();
    };
    const handleButtonClick_send = async (text) => {
        let message = [{userId: userId, message: text}];
        setMessages((prev) => prev.concat(message));
        setInput('');
        const chatbotReply = await getChatbotReply(text, roomId, userId);
        setMessages((prev) => prev.concat({userId: 'Accio', message: chatbotReply}));
    };
    const handleButtonClick_fetch = () => {
        fetchChatHistory();
    };

    const [lastProductHistoryIndex, setLastProductHistoryIndex] = useState(-1);
    const handleUpdateProductHistory = () => {
        //console.log("update product history");
        updateProductHistory();
    };



    //COMM: Move VoiceOver focus to HEADER
    useEffect(() => {
        setTimeout(() => {
            const header = document.getElementById('HEADER');
            if (header) {
                header.tabIndex = -1;
                header.focus();
            }
        }, 250);
    }, []);

    //COMM: Change page title
    useEffect(() => {
        document.title = '나만의 AI 쇼핑 메이트 아씨오';
    }, []);

    const [randomGreeting, setRandomGreeting] = useState('');
    const [randomEX1Label, setRandomEX1Label] = useState([]);
    const [randomEX1Text, setRandomEX1Text] = useState([]);
    const [randomEX2Label, setRandomEX2Label] = useState([]);
    const [randomEX2Text, setRandomEX2Text] = useState([]);
    const [randomEX3Label, setRandomEX3Label] = useState([]);
    const [randomEX3Text, setRandomEX3Text] = useState([]);
    //COMM: Set greeting text
    useEffect(() => {
		// console.log('what kakaonick? ', kakaoNick);
		let greeting_text1;
		let greeting_text2;
		if (kakaoNick === 'guest') {
			greeting_text1 = `안녕하세요 😀 오늘은 어떤 상품을 찾고 계세요? 무엇이든 물어봐 주세요!`;
        	greeting_text2 = `안녕하세요 🧐 어떤 상품을 찾아드릴까요? 아씨오가 추천해드릴게요.`;	
		} else {
			greeting_text1 = `${kakaoNick}님 안녕하세요 😀 오늘은 어떤 상품을 찾고 계세요? 무엇이든 물어봐 주세요!`;
			greeting_text2 = `${kakaoNick}님 안녕하세요 🧐 어떤 상품을 찾아드릴까요? 아씨오가 추천해드릴게요.`;	
		}

        const greeting_text = [greeting_text1, greeting_text2];
        const greeting_index = Math.floor(Math.random() * greeting_text.length);
        setRandomGreeting(greeting_text[greeting_index]);
    }, [kakaoNick]);
    //COMM: Set button labels and text
    useEffect(() => {
        const example1_index = Math.floor(Math.random() * chatData.example1_label.length);
        setRandomEX1Label(chatData.example1_label[example1_index]);
        setRandomEX1Text(chatData.example1_text[example1_index]);

        const example2_index = Math.floor(Math.random() * chatData.example2_label.length);
        setRandomEX2Label(chatData.example2_label[example2_index]);
        setRandomEX2Text(chatData.example2_text[example2_index]);

        const example3_index = Math.floor(Math.random() * chatData.example3_label.length);
        setRandomEX3Label(chatData.example3_label[example3_index]);
        setRandomEX3Text(chatData.example3_text[example3_index]);
    }, []);

    const initial_label = ['🤨 아씨오는 뭘 할 수 있어?'];
    const initial_text = ['아씨오는 뭘 할 수 있어?'];
    const search_label = ['🧐 다른 상품 더 추천해줘', '😁 이거 마음에 들어', '😯 잘못된 추천이야'];
    const search_text = [
        '같은 조건으로 다른 상품 더 추천해줘',
        '오 이거 마음에 들어!',
        '내가 찾는 상품은 이게 아니야',
    ];
    const SERVER_ERROR_MESSAGES = [
        '현재 서버 이용량이 많아 일시적으로 이용이 제한될 수 있습니다. 잠시 후에 다시 이용해주세요.',
        '일시적으로 오류가 발생했어요. 잠시 후에 다시 물어봐 주시겠어요?',
    ];
	
    // Top-toggle switching style
	useEffect(() => {
		const location = window.location.pathname;
		// console.log(location);
		if (location.includes("expert")) {
			document.getElementById('expert').style.background = "#ffffff";	
		} else {
			document.getElementById('general').style.background = "#ffffff";
		}
		
	},[])
	
	//Top-toggle function
	const handleTopToggle = (e) => {
		if (isGeneratingAnswer) {
			return;
		}
		let id = e.target.id;
		// console.log('toggle id: ', id);
		switch (id) {
			case "general":
				navigate(`/chat-room/${roomId}/${userId}`);
				break;
			case "expert":
				navigate(`/chat-room/${roomId}/${userId}/expert`);
				break;
		}
	}

    return (
        <div className={style.Chat__chatWrap}>
			<div className={style.Chat__toggleGroup}>
				<div className={style.Chat__toggleBack}>
					<button 
						id="general"
						className={style.Chat__toggle} 
						onClick={(e) => {handleTopToggle(e)}}
					>
						아씨오
					</button>
					<button 
						id="expert"
						className={style.Chat__toggle} 
						onClick={(e) => {handleTopToggle(e)}}
					>
						전통주 전문가
					</button>
				</div>
			</div>
            <div className={style.Chat__flex__row} style={{ height: '100%' }}>
                {messages.length === 0 && !isMobileDevice && (
                    <div className={`${style.Chat__main} ${style.Chat__flex}`}>
                        <Stack style={{ width: '100%', maxWidth: '900px' }}>
                            <Stack direction="row" sx={{ mb: 1.5, flex: '0 0 auto' }}>
                                <div id="HEADER" className={style.Chat__box__p16}>
                                    <div
                                        className={`${isExpert ? style.Chat__iconExp : style.Chat__icon}`}
                                        style={{ objectFit: 'cover' }}
                                        role="heading"
                                        aria-level="3"
                                    />
                                    <TypographyComponent
                                        variant="body1"
                                        align="left"
                                        fontWeight="bold"
                                        fontSize="1.6rem"
                                        color="#4D4D4D"
                                        marginTop="8px"
                                        marginBottom="8px"
										sx={{width: "100%"}}
                                    >
                                        {randomGreeting}
                                    </TypographyComponent>
                                </div>
                            </Stack>

                            <Stack
                                direction="row"
                                sx={{ flex: '0 0 auto' }}
                                role="region"
                                aria-label="추천 예시"
                            >
                                <Button
                                    onClick={() => handleButtonClick_fill(randomEX1Text)}
                                    className={style.Chat__example}
                                    disableRipple
                                    aria-label={randomEX1Text}
                                >
                                    <TypographyComponent
                                        variant="body1"
                                        align="left"
                                        fontWeight="medium"
                                        fontSize="1.6rem"
                                    >
                                        {randomEX1Label}
                                    </TypographyComponent>
                                </Button>
                                <Button
                                    onClick={() => handleButtonClick_fill(randomEX2Text)}
                                    className={style.Chat__example}
                                    disableRipple
                                    aria-label={randomEX2Text}
                                    sx={{ marginLeft: 1.5, marginRight: 1.5 }}
                                >
                                    <TypographyComponent
                                        variant="body1"
                                        align="left"
                                        fontWeight="medium"
                                        fontSize="1.6rem"
                                    >
                                        {randomEX2Label}
                                    </TypographyComponent>
                                </Button>
                                <Button
                                    onClick={() => handleButtonClick_fill(randomEX3Text)}
                                    className={style.Chat__example}
                                    disableRipple
                                    aria-label={randomEX3Text}
                                >
                                    <TypographyComponent
                                        variant="body1"
                                        align="left"
                                        fontWeight="medium"
                                        fontSize="1.6rem"
                                    >
                                        {randomEX3Label}
                                    </TypographyComponent>
                                </Button>
                            </Stack>
                        </Stack>
                    </div>
                )}
                {messages.length === 0 && isMobileDevice && (
                    <div className={style.Chat__mainmd}>
                        <Stack
                            direction="column"
                            sx={{ mb: '10vh', flex: '0 0 auto', flexWrap: 'wrap' }}
                        >
                            <Stack direction="row" sx={{ flex: '0 0 auto' }}>
                                <Box
                                    id="HEADER"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: 'auto',
                                        flex: 1,
                                        borderRadius: '10px',
                                        padding: '16px',
                                        marginTop: '10vh',
                                        marginBottom: '1vh',
                                        border: '1px solid #CCC',
                                    }}
                                >
                                    <div
                                        className={`${isExpert ? style.Chat__iconExp : style.Chat__icon}`}
                                        style={{ objectFit: 'cover' }}
                                        role="heading"
                                        aria-level="3"
                                    />
                                    <TypographyComponent
                                        variant="body1"
                                        align="left"
                                        fontWeight="bold"
                                        fontSize="1.6rem"
                                        color="#4D4D4D"
										sx={{width: "100%", maxWidth: "90%"}}
                                    >
                                        {randomGreeting}
                                    </TypographyComponent>
                                </Box>
                            </Stack>

                            <Stack
                                direction="column"
                                sx={{ mb: '10vh', flex: '0 0 auto', flexWrap: 'wrap' }}
                                role="region"
                                aria-label="추천 예시"
                            >
                                <Button
                                    onClick={() => handleButtonClick_fill(randomEX1Text)}
                                    className={style.Chat__example_mobile}
                                    disableRipple
                                    aria-label={randomEX1Text}
                                >
                                    <TypographyComponent
                                        variant="body1"
                                        align="left"
                                        fontWeight="medium"
                                        fontSize="1.6rem"
                                    >
                                        {randomEX1Label}
                                    </TypographyComponent>
                                </Button>
                                <Button
                                    onClick={() => handleButtonClick_fill(randomEX2Text)}
                                    className={style.Chat__example_mobile}
                                    disableRipple
                                    aria-label={randomEX2Text}
                                >
                                    <TypographyComponent
                                        variant="body1"
                                        align="left"
                                        fontWeight="medium"
                                        fontSize="1.6rem"
                                    >
                                        {randomEX2Label}
                                    </TypographyComponent>
                                </Button>
                                <Button
                                    onClick={() => handleButtonClick_fill(randomEX3Text)}
                                    className={style.Chat__example_mobile}
                                    disableRipple
                                    aria-label={randomEX3Text}
                                >
                                    <TypographyComponent
                                        variant="body1"
                                        align="left"
                                        fontWeight="medium"
                                        fontSize="1.6rem"
                                    >
                                        {randomEX3Label}
                                    </TypographyComponent>
                                </Button>
                            </Stack>
                        </Stack>
                    </div>
                )}
                {messages.length !== 0 && (
                    <div style={{ width: '100%', height: '100%' }}>
                        <div
                            id="ChatField"
                            className={`${isMobileDevice ? style.Chat__mainmd : style.Chat__main} ${
                                style.Chat__flex
                            }`}
                        >
                            <div
                                className={style.Chat__list}
                                role="region"
                                aria-label="대화"
                                aria-live="polite"
                            >
                                <Message userId="Accio" text={randomGreeting} isExpert={isExpert}>
                                    {' '}
                                </Message>
                                {messages.map(({ userId, message }, index) => {
                                    if (
                                        message.includes('상품 링크:') &&
                                        index > lastProductHistoryIndex
                                    ) {
                                        handleUpdateProductHistory();
                                        setLastProductHistoryIndex(index);
                                    }
                                    return (
                                        <Message
                                            key={`${userId}_${index}`}
                                            userId={userId}
                                            myId={myId}
                                            text={message}
											isExpert={isExpert}
                                        >
                                            {' '}
                                        </Message>
                                    );
                                })}
                                <div ref={messagesEndRef} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className={isMobileDevice ? style.Chat__back : null}></div>
            <div className={isMobileDevice ? style.Chat__bottommd : style.Chat__flex}>
                <div className={isMobileDevice ? style.Chat__inputmd : style.Chat__input}>
                    <div className={style.Chat__inlineBtn}>
                        {messages.length !== 0 &&
                            SERVER_ERROR_MESSAGES.includes(
                                messages[messages.length - 1].message
                            ) && (
                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        submitMessage(messages[messages.length - 2].message)
                                    }
                                    className={style.Chat__recommend__errorButton}
                                    style={{ minWidth: '15ch', marginRight: '4px' }}
                                    disableRipple
                                    aria-label="다시 시도하기"
                                >
                                    <TypographyComponent variant="button" fontWeight="medium" fontSize="1.6rem">
                                        ⚠️ 다시 시도하기
                                    </TypographyComponent>
                                </Button>
                            )}
                        {
                            <Button
                                id="SEARCH"
                                variant="contained"
                                onClick={() => handleButtonClick_fill('/검색 ')}
                                className={style.Chat__recommend__highlightButton}
                                style={{
                                    minWidth: '16.5ch',
                                    border: '1px solid #154CCB',
                                    marginRight: '4px',
                                }}
                                disableRipple
                                aria-label="실시간 정보 검색"
                            >
                                <TypographyComponent variant="button" fontWeight="medium" fontSize="1.6rem">
                                    ⚡ 실시간 정보 검색
                                </TypographyComponent>
                            </Button>
                        }
                        {messages.length === 0 && (
                            <>
                                {
									kakaoNick !== "guest" && (
										<Button
											variant="contained"
											onClick={() => handleButtonClick_fetch()}
											className={style.Chat__recommend__button}
											style={{ minWidth: '20.5ch', marginRight: '4px' }}
											disableRipple
											aria-label="이전 대화내역 불러오기"
										>
											<TypographyComponent variant="button" fontWeight="medium" fontSize="1.6rem">
												🙄 이전 대화내역 불러오기
											</TypographyComponent>
										</Button>
									)
								}
								
                                {initial_label.map((label, index) => (
                                    <Button
                                        key={index}
                                        variant="contained"
                                        onClick={() => handleButtonClick_send(initial_text[index])}
                                        className={style.Chat__recommend__button}
                                        style={{
                                            minWidth: `${label.length + 4}ch`,
                                            marginRight: '4px',
                                        }}
                                        disableRipple
                                    >
                                        <TypographyComponent variant="button" fontWeight="medium" fontSize="1.6rem">
                                            {label}
                                        </TypographyComponent>
                                    </Button>
                                ))}
                            </>
                        )}
                        {messages.length !== 0 && (
                            <Button
                                variant="contained"
                                onClick={() => handleButtonClick_send('새로운 상품 찾을래')}
                                className={style.Chat__recommend__button}
                                style={{ minWidth: '18ch', marginRight: '4px' }}
                                disableRipple
                                aria-label="새로운 상품 찾을래"
                            >
                                <TypographyComponent variant="button" fontWeight="medium" fontSize="1.6rem">
                                    😊 새로운 상품 찾을래
                                </TypographyComponent>
                            </Button>
                        )}
                        {messages.length !== 0 &&
                            messages[messages.length - 1].message.includes('상품 링크:') && (
                                <>
                                    {search_label.map((label, index) => (
                                        <Button
                                            key={index}
                                            variant="contained"
                                            onClick={() =>
                                                handleButtonClick_send(search_text[index])
                                            }
                                            className={style.Chat__recommend__button}
                                            style={{
                                                minWidth: `${label.length + 4}ch`,
                                                marginRight: '4px',
                                            }}
                                            disableRipple
                                        >
                                            <TypographyComponent
                                                variant="button"
                                                fontWeight="medium"
                                                fontSize="1.6rem"
                                            >
                                                {label}
                                            </TypographyComponent>
                                        </Button>
                                    ))}
                                </>
                            )}
                    </div>
                    <TextField
                        id="TextField"
						className={style.Chat__inputfield}
                        value={input}
                        inputRef={inputRef}
                        onChange={onChange}
                        onKeyPress={handleKeyPress}
                        style={{ width: '100%', marginTop: '8px' }}
                        multiline
                        minRows={1}
                        maxRows={3}
                        placeholder="아씨오에게 무엇이든 물어보세요"
                        inputProps={{ 'aria-label': '메시지' }}
                        InputProps={{
                            style: {
                                backgroundColor: input.startsWith('/검색')
                                    ? 'rgba(21, 76, 203, 0.15)'
                                    : '#F5F5F5',
                                fontWeight: input.startsWith('/검색') ? 'bold' : 'normal',
                                fontSize: '1.6rem',
                            },
                            endAdornment: (
                                <>
                                    {isGeneratingAnswer ? (
                                        <CircularProgress
                                            id="LOADING"
                                            size={20}
                                            aria-label="로딩 중"
                                            role="alert"
                                        />
                                    ) : (
                                        <Button
                                            id="SEND"
                                            variant="contained"
                                            color="primary"
                                            onClick={(e) => onClick(e)}
                                            className={style.Chat__textfield__button}
                                            sx={{
                                                minWidth: '32px',
                                                minHeight: '32px',
                                                position: 'absolute',
                                                right: '6px',
                                                bottom: '12px',
                                            }}
                                            aria-label="전송하기"
                                        >
                                            <FiSend fontSize={16} color="grey" />
                                        </Button>
                                    )}
                                </>
                            ),
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ChatField