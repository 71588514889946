import { React, useEffect } from 'react';
import { ToastContainer } from "react-toastify";
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import style from './page.module.css';
import 'react-toastify/dist/ReactToastify.css';

const ChatPageDemo = () => {
	const randomId = parseInt(Math.random()*1e9);
	const navigate = useNavigate();
	const redirectStat = sessionStorage.getItem('redirect');

	useEffect(() => {
		if (redirectStat) {
			navigate(`/demo/${randomId}/drink`);
		} else {
			navigate(`/404`)
		};
	}, [])
	
    return (
        <div className={style.Page}>
			<Outlet />
            <ToastContainer/>
        </div>
    );
}

export default ChatPageDemo;