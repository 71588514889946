import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";


const Auth = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    sessionStorage.setItem('udid', searchParams.get('udid'));
    sessionStorage.setItem('auth', searchParams.get('authorization'));
    sessionStorage.setItem('redirect', true);
    
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/demo');
    },[]);
}

export default Auth