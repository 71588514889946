import React, { useEffect } from 'react';
import ChatRoomEntry from '../containers/ChatRoomEntry';
import { ToastContainer } from "react-toastify";

import style from './page.module.css';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const Main = () => {
    const navigate = useNavigate();

    useEffect(() => {
		navigate(`/demo`);
	}, [])

    return (
        <div className={style.Page}>
            <ChatRoomEntry /> 
            <ToastContainer/>
        </div>
    );
}

export default Main;

