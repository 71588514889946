import React from 'react';
import { ToastContainer } from "react-toastify";
import { useParams, Outlet } from 'react-router-dom';

import style from './page.module.css';
import 'react-toastify/dist/ReactToastify.css';

const Chat = () => {
	let params = useParams();
	// console.log('params user: ', params.userId);
	
    return (
        <div className={style.Page}>
			<Outlet />
            <ToastContainer/>
        </div>
    );
}

export default Chat;