import React, { useEffect, useState } from 'react';
import style from './Message.module.css';
import Linkify from 'react-linkify';
import styled from 'styled-components';
import { Link, json, useNavigate } from 'react-router-dom';
import { addComma, genStars, submitMessage } from '../../containers/ChatFuncDemo';
import { chatDataDrink } from '../../data/chatData';

const Message = (props) => {
	const { userId, text, myId, isExpert, example, product, handleButtonClick_send, setMessages, setFeedbackStat } = props;
	const isMyMessage = userId !== 'Accio';
	const navigate = useNavigate();
	let iconStyle = '';
	let borderStyle = style.Message__list;
	
	if (isMyMessage) {
		iconStyle = (isExpert ? style.Message__myIconExp : style.Message__myIcon);
	} else {
		iconStyle = (isExpert ? style.Message__iconExp : style.Message__icon);
	}

	const randomIdxArr = [
		Math.floor(Math.random() * chatDataDrink.example1_label.length),
		Math.floor(Math.random() * chatDataDrink.example2_label.length),
		Math.floor(Math.random() * chatDataDrink.example3_label.length),
	];

	const randomTextArr = JSON.stringify([
		chatDataDrink.example1_label[randomIdxArr[0]],
		chatDataDrink.example2_label[randomIdxArr[1]],
		chatDataDrink.example3_label[randomIdxArr[2]]
	]);

	useEffect(() => {
		if (text.includes('새로운 대화하기')) {
			sessionStorage.setItem('randomText', randomTextArr);
		}
	}, [text])
	
	// product carousel
	if (product) {
		return (
			<>
				<li className={borderStyle} >
					<span 
						className={iconStyle} 
						role="heading" 
						aria-level="3" 
					/>
					<div className={style.Message__content}>
						<div className={style.Message__text}>
							<p>{text}</p>
						</div>
					</div>
				</li>
				<Container>
					<FlexContainer $width={233 * product.length + "px"} $justifyContent="flex-start" $padding="0 0 8px 0">
						{
							product.map((data, idx) => {
								return (
									<CarouselWrap key={idx} $first={idx === 0}>
										<ImgSection $height="140px">
											<CardImg $background={`url(${data.imageUrl})`}/>
										</ImgSection>
										<Section $height="164px" $padding="10px 16px">
											<Label $first={idx === 0} >{idx === 0 ? "추천 상품" : "관련 상품"}</Label>
											<Contents $width="100%" $fontWeight="900" $margin="4px 0">{data.name}</Contents>
											<FlexContainer $fontSize="12px" $justifyContent="flex-end" $minHeight="20px">
												{data.discount !== 0 && 
													<>
														<span style={{textDecoration: "line-through", color: "#ccc", fontSize: "14px"}}>{addComma(data.originPrice)}</span>
														<span style={{color: "red", fontSize: "14px"}}> {data.discount}%</span>
													</>
												}
											</FlexContainer>
											<FlexContainer $justifyContent="space-between">
												{/* <Contents $color="#999">가격</Contents> */}
												{
													data.rate === -1 ?
													<div style={{minWidth: "91px"}}></div> :
													<FlexContainer $width="auto" $justifyContent="flex-start" $alignItems="flex-end">
														<span>
															{genStars(data.rate)}
														</span>
														<p style={{ fontSize: '12px', lineHeight: '1', marginLeft: '4px' }}>
															{data.rate?.toFixed(1)}
														</p>
													</FlexContainer>
												}
												<Contents $fontSize="20px">{addComma(data.price)}원</Contents>
											</FlexContainer>
											<hr color='#ddd' style={{margin: "4px 0"}}/>
											<p style={{fontSize: "12px", color: "#999", minHeight: "34px"}}>{data.shortDesc}</p>
											<LinkWrap href={data.productUrl}>
												<StyledBtn $width="100%" $margin="8px 0 0 0" color='#fff' >상품 상세 보기</StyledBtn>
											</LinkWrap>
										</Section>
									</CarouselWrap>

								)
							})
						}
					</FlexContainer>
				</Container>
				{
					example?.length > 0 &&
					<FlexContainer $flexDirection="column" $justifyContent="flex-start" $alignItems="flex-start" $padding="0 0px 8px 44px" style={{overflowX: "scroll"}}>
						{
							example?.map((data, idx) => {
								return (
									<ExamBtn key={idx} data-value={data} onClick={(e) => {handleButtonClick_send(e.target.dataset.value)}}>{data}</ExamBtn>
								)
							})
						}
					</FlexContainer>
				}
			</>
		)
	// user chat message return
	} else {
		const randomText = JSON.parse(sessionStorage.getItem('randomText'));
		return (
			<>
				<li className={borderStyle}>
					<span 
						className={iconStyle} 
						role="heading" 
						aria-level="3" 
					/>
					<div className={isMyMessage ? style.Message__content__my : style.Message__content} >
						<div className={style.Message__text}>
							{text}
						</div>
					</div>
				</li>
				{<p>{text.includes("새로운 대화를 시작했어요")}</p>}
				{
					text.includes("새로운 대화를 시작했어요") &&
					<FlexContainer $flexDirection="column" $justifyContent="flex-start" $alignItems="flex-start" $margin="-8px 0 0 0" $padding="0 0px 8px 44px" style={{overflowX: "scroll"}}>
						<ExamBtn data-value={randomText[0]} onClick={(e) => {handleButtonClick_send(e.target.dataset.value)}}>{randomText[0]}</ExamBtn>
						<ExamBtn data-value={randomText[1]} onClick={(e) => {handleButtonClick_send(e.target.dataset.value)}}>{randomText[1]}</ExamBtn>
						<ExamBtn data-value={randomText[2]} onClick={(e) => {handleButtonClick_send(e.target.dataset.value)}}>{randomText[2]}</ExamBtn>
					</FlexContainer>
				}
				{
					example?.length > 0 &&
					<FlexContainer $flexDirection="column" $justifyContent="flex-start" $alignItems="flex-start" $margin="-4px 0 0 0" $padding="0 0px 8px 44px">
						{
							example?.map((data, idx) => {
								return (
									<ExamBtn key={idx} data-value={data} onClick={(e) => {handleButtonClick_send(e.target.dataset.value)}}>{data}</ExamBtn>
								)
							})
						}
					</FlexContainer>
				}
			</>
		)
	}
};

export default Message;

const Container = styled.div`
	width: 100%;
	padding-left: 44px;
	overflow-x: scroll;
`

const FlexContainer = styled.div`
	width: ${props => props.$width || "100%"};
	min-height: ${props => props.$minHeight || 0};
	display: flex;
	flex-direction: ${props => props.$flexDirection || "row"};
	align-items: ${props => props.$alignItems || "center"};
	justify-content: ${props => props.$justifyContent || "center"};
	margin: ${props => props.$margin || 0};
	padding: ${props => props.$padding || 0};
`

const CarouselWrap = styled.div`
	width: 230px;
	height: 334px;
	background: #fff;
	border-radius: 10px;
	box-shadow: 1px 2px 2px #d8d8d8;
	border: ${props => props.$first ? "1px solid #ed672e" : "none"};
	overflow: hidden;
	margin: 0 2px;
`
const Section = styled.div`
	width: 100%;
	height: ${props => props.$height || "fit-content"};
	padding: ${props => props.$padding || 0};
`

const ImgSection = styled(Section)`
	display: flex;
	justify-content: center;
`

const CardImg = styled.div`
	width: 140px;
	height: 140px;
	background: ${props => props.$background || ""};
	background-size: cover;
	background-position: center;
`

const Label = styled.div`
	width: 60px; 
	font-size: 10px;
	color: ${props => props.$first ? "#ed672e" : "#999"};
	border: ${props => props.$first ? "1px solid #ed672e" : "1px solid #999"};
	border-radius: 10px;
	text-align: center;
	padding: 0 4px;
`

const StyledBtn = styled.button`
	width: ${props => props.$width || "fit-content"};
	min-width: fit-content;
	height: fit-content;
	border: ${props => props.$border || "none"}; 
	border-radius: 10px;
	background: #ed672e;
	color: #fff;
	padding: 4px 12px;
	margin: ${props => props.$margin || 0};
	font-size: 12px;
	font-weight: 600;
	text-decoration: none;
	text-align: center;
	text-wrap: pretty;
`

const ExamBtn = styled(StyledBtn)`
	background: #fff;
	// color: #FFB340;
	// color: #ed672e;
	color: #ff972e;
	font-size: 12px;
	text-align: left;
	margin: 0 0 4px 0;
`

const LinkWrap = styled.a`
	text-decoration: none;
`

const Contents = styled.p`
	width: ${props => props.$width || "fit-content"};
	font-size: ${props => props.$fontSize || "14px"};
	font-weight: ${props => props.$fontWeight || "600"};
	color: ${props => props.$color || "#222"};
	margin: ${props => props.$margin || "0"};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

