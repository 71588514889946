import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

//COMM: import style
import style from './Chat.module.css';

//COMM: import images and icons
import hyperClova from '../../images/HyperClova.png';
import { FiInfo, FiLogOut, FiBookmark } from 'react-icons/fi';

//COMM: import loadScript for Kakao login
import loadScript from 'load-script';

//COMM: import materialUI
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography as TypographyComponent } from '@mui/material';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';

//COMM: import Modal
import Modal from 'react-modal';
import ChatField from './ChatField.jsx'
Modal.setAppElement('#root');

//COMM: ModalComponent contains guideline info.
//		Opens when '안내사항' button in the sidebar is clicked, Closes when '닫기' button within the modal is clicked.
const ModalComponent = ({ isOpen, onClose }) => {
    return (
        //TASK: used style overlay and content only because I didn't know how to declare it in css.
        //		therefore, if you have time and knowledge, please move the following COMM(stye={{}}) to css. */}
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={{
                overlay: { backgroundColor: 'rgba(0, 0, 0, 0)', zIndex: 9999 },
                content: {
                    position: 'fixed',
                    top: 'auto',
                    bottom: '1.2rem',
                    left: '1.6rem',
                    right: 'auto',
                    width: '20vw',
                    minWidth: '22.4rem',
                    height: 'auto',
                    margin: '0 auto',
                    padding: '2.4rem',
                    backgroundColor: '#F5F5F5',
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                },
            }}
        >
            {[
                {
                    text: '⚡ 일상적인 대화도 가능하지만 상품 추천에 최적화 되어있어요.',
                    marginBottom: '0.8rem',
                },
                {
                    text:
                        '🚧 부정확한 정보가 포함될 수 있어요. 구매 전 상세 정보를 꼭 확인해주세요.',
                    marginBottom: '0.8rem',
                },
                {
                    text:
                        '🔒 대화내역은 성능 개선에 이용될 수 있어요. 민감정보 유출에 유의해주세요.',
                    marginBottom: '0.8rem',
                },
                {
                    text:
                        "⚠️ 대화가 길어지면 잘못된 추천을 할 수 있어요. 이럴 때는 '새로운 상품 찾을래' 버튼을 눌러주세요.",
                    marginBottom: '4rem',
                },
            ].map((item, index) => (
                <TypographyComponent
                    key={index}
                    variant="body2"
                    style={{
                        marginTop: index === 0 ? '0.8rem' : '0',
                        marginBottom: item.marginBottom,
                    }}
                >
                    {item.text}
                </TypographyComponent>
            ))}

            <Box className={style.Modal__hyperClova}>
                <img
                    style={{ objectFit: 'cover', width: '16rem', height: 'auto' }}
                    src={hyperClova}
                    alt="powered by HyperClova"
                />
            </Box>
            <button onClick={onClose} className={style.Modal__button}>
                닫기
            </button>
        </Modal>
    );
};

//COMM: SidebarComponent contains list of product search history, and buttons '안내사항' and '로그아웃'
//		Sidebar on the left when the screen width if 1080px or larger, Topbar when the screen width is smaller than 1080px
const SidebarComponent = ({ productHistory }) => {
    const [isSidebar, setIsSidebar] = useState(true);
    //COMM: use mediaQuery to determine wheter to render sidebar or topbar
    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 1080px)');
        const handleMediaQueryChange = (e) => {
            setIsSidebar(!e.matches);
        };
        mediaQuery.addEventListener('change', handleMediaQueryChange);
        setIsSidebar(!mediaQuery.matches);

        return () => {
            mediaQuery.removeEventListener('change', handleMediaQueryChange);
        };
    }, []);

    const navigate = useNavigate();
    const handleLogout = () => {
        // console.log('handleLogout');
        loadScript('https://developers.kakao.com/sdk/js/kakao.js', () => {
            window.Kakao.Auth.logout();
        });
        localStorage.removeItem('token');
        navigate('/');
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const handleButtonClick_info = () => {
        openModal();
    };
    const handleButtonClick_url = (index) => {
        window.open(buttonUrl[index], '_blank');
    };

    const [productList, setProductList] = useState([]);
    //COMM: Load and reload product search history whenever there is change
    useEffect(() => {
        if (!productHistory) {
            //console.log("ProductHistory is undefined or empty.");
            return;
        }
        productHistory.then((promiseResult) => {
            if (promiseResult.length === 0) {
                //console.log("ProductHistory is empty.");
                return;
            }
            const productDataList = promiseResult.map((data, index) => {
                return {
                    date: data.date,
                    name: data.productName,
                    url: data.productUrl,
                };
            });
            const curList = productList;
            const newList = productDataList;
            setProductList([...newList, ...curList]);
        });
    }, [productHistory]);

    const [buttonLabel, setButtonLabel] = useState([]);
    const [buttonUrl, setButtonUrl] = useState([]);
    //COMM: Update new product search history
    useEffect(() => {
        //console.log("ProductList has been updated:", productList);
        const labelList = productList.map((product) => product.name);
        setButtonLabel(labelList);
        const urlList = productList.map((product) => product.url);
        setButtonUrl(urlList);
    }, [productList]);

    return (
        <div className={isSidebar ? style.Sidebar : style.Topbar} style={{ position: 'absolute' }}>
            {isSidebar && (
                <div style={{ overflowY: 'scroll', height: 'calc(100vh - 12rem)' }}>
                    <div
                        direction="column"
                        className={style.Sidebar__productList}
                        sx={{
                            overflowX: 'hidden',
                            overflowY: 'hidden',
                            scrollbarWidth: 'none',
                            scrollbarWidth: 'none',
                            '-ms-overflow-style': 'none',
                            '&::-webkit-scrollbar': { display: 'none' },
                        }}
                    >
                        {buttonLabel.map((label, index) => (
                            <Button
                                variant="contained"
                                onClick={() => handleButtonClick_url(index)}
                                className={style.Sidebar__productList__product}
                                disableRipple
								key={index}
                            >
                                <FiBookmark style={{ position: 'absolute' }} />
                                <span style={{ position: 'absolute', marginLeft: '2rem' }}>
                                    {' '}
                                    {label}{' '}
                                </span>
                            </Button>
                        ))}
                    </div>
                    <Button
                        variant="contained"
                        onClick={handleButtonClick_info}
                        className={style.Sidebar__button}
                        sx={{ bottom: '4.8rem' }}
                        style={{ position: 'absolute' }}
                        disableRipple
                        aria-label="안내사항"
                        aria-haspopup="dialog"
                    >
                        <FiInfo style={{ marginRight: '0.8rem' }} /> 안내사항
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleLogout}
                        className={style.Sidebar__button}
                        sx={{ bottom: '1.2rem' }}
                        style={{ position: 'absolute' }}
                        disableRipple
                    >
                        <FiLogOut style={{ marginRight: '0.8rem' }} /> 로그아웃
                    </Button>
                </div>
            )}
            {!isSidebar && (
                <Button
                    variant="contained"
                    onClick={handleLogout}
                    className={style.Topbar__button}
                    style={{ position: 'absolute' }}
                    disableRipple
                >
                    로그아웃
                </Button>
            )}
            <ModalComponent isOpen={isModalOpen} onClose={closeModal} />
        </div>
    );
};

//COMM: Main Component that contains messages, textfield, etc.
const Chat = ({
    messages,
    setMessages,
    submitMessage,
    myId,
    kakaoNick,
    isGeneratingAnswer,
    fetchChatHistory,
    updateProductHistory,
    getChatbotReply,
    productHistory,
	chatData,
    resetQueryCondition,
}) => {
	const navigate = useNavigate();
	const { roomId, userId } = useParams();
	const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const handleButtonClick_info = () => {
        openModal();
    };
	
	//COMM: Create theme for textfield
    const theme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#154CCB' },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#154CCB',
                        },
                        borderRadius: '10px',
                    },
                },
            },
        },
    });
	
    return (
        <ThemeProvider theme={theme}>
            <SidebarComponent productHistory={productHistory} />
            <ModalComponent isOpen={isModalOpen} onClose={closeModal} />
            <ChatField
				messages={messages}
                setMessages={setMessages}
				submitMessage={submitMessage}
				myId={myId}
				kakaoNick={kakaoNick}
				isGeneratingAnswer={isGeneratingAnswer}
				fetchChatHistory={fetchChatHistory}
				updateProductHistory={updateProductHistory}
                getChatbotReply={getChatbotReply}
				productHistory={productHistory}
				chatData={chatData}
                resetQueryCondition={resetQueryCondition}
            />
        </ThemeProvider>
    );
};

export default Chat;