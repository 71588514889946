import { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import axios from 'axios';

import ChatComp from '../components/Chat/Chat';
import { chatData } from '../data/chatData.js'

const ChatFunc = () => {
    let { roomId, userId } = useParams();
    const [ messages, setMessages ] = useState([]);
	const [ isGeneratingAnswer, setIsGeneratingAnswer ] = useState(false);
    const navigate = useNavigate();
	const [ kakaoNick, setKakaoNick ] = useState('');
	const [ productHistory, setProductHistory ] = useState('');
	
    const addMessage = (message) => {
        setMessages((prev) => prev.concat(message));
    }
	
	const fetchChatHistory = async () => {
		try {
			const response = await axios.post(process.env.REACT_APP_NORM_CHAT_LOAD_URL, {
				kakaoId: roomId,
				loginType: userId,
			});
			// console.log('chat history res: ', response);
			if (response.data.body) {
				const formattedChatHistory = response.data.body.map((chat, index) => {
					return {
						userId: chat.role === 'user' ? userId : 'Accio',
						message: chat.content,
					};
				});
				// console.log('fetchChatHistory setMessages called');
				setMessages(formattedChatHistory);
			} else {
				throw new Error('Invalid server response');
			}
		} catch (error) {
			console.error(`Error while fetching chat history: ${error.message}`);
		}
	};
	
	const fetchProductHistory = async (isNewSearch) => {
		try {
			// console.log("userNick: ", roomId);
			// console.log("userPW: ", userId);
			// console.log("isNewSearch: ", isNewSearch);
			const response = await axios.post(process.env.REACT_APP_NORM_PRODUCT_LOAD_URL, {
				kakaoId: roomId,
				loginType: userId,
				isNewSearch: isNewSearch,
			});
			// console.log('load-product res: ', response);
			if (response.data.body) {
				const formattedProductHistory = response.data.body.map((product, index) => {
					return {
						date: product.Date,
						productName: product.ProductName,
						productUrl: product.ProductUrl,
					};
				});
				return formattedProductHistory;
			} else {
				throw new Error('Invalid server response');
			}
		} catch (error) {
			console.error(`Error while fetching product history: ${error.message}`);
		}
	};
	
	const updateProductHistory = async () => {
		setProductHistory(fetchProductHistory(1));
	};



	useEffect(() => {
		async function fetchToken() {
			// console.log('try fectchToken');
			// console.log('roomId: ', roomId);
			// console.log('userId: ', userId);
			try {
				const storedToken = localStorage.getItem('token');

				let curToken, renewedToken = '';
				const checkRes = await axios.post(process.env.REACT_APP_KAKAO_TOKEN_REQ, {
					kakaoId: roomId,
					loginType: userId,
					token: ''
				});
				// console.log('checkRes @ChatFunc: ', checkRes);
				
				if (checkRes.data && checkRes.data.body.token) {
					curToken = checkRes.data.body.token;
					renewedToken = checkRes.data.body.renewedToken;
					// console.log('storedToken init: ', storedToken);
					// console.log('curToken: ', curToken);
					// console.log('renewedToken: ', renewedToken);
					
					if (storedToken !== "login" && curToken !== storedToken) {
						console.log("Token Match Failed");
						navigate('/');
					}
					else {
						localStorage.setItem("token", renewedToken);
						// console.log('storedToken updated: ', localStorage.getItem('token'));
					}
					setProductHistory(fetchProductHistory(0));
				} else {
					console.log("Token Invalid");
					navigate('/');
				}
				// console.log('@update message: ', messages);
				setKakaoNick(checkRes.data.body.kakaoNick);
			} catch (error) {
				console.log('error: ', error)
				navigate('/');
			}
		}
		fetchToken();
	}, []);
	
	// const getChatbotReply = async (userMessage, roomId, userId) => {
	// 	try{
	// 		setIsGeneratingAnswer(true);
	// 		const curToken = localStorage.getItem("token");
	// 		const response = await axios.post('https://accio-dev-server.run.goorm.app/chatbot', {
	// 			message: userMessage,
	// 			userNick: roomId,
	// 			userPW: userId,
	// 			token: curToken,
	// 			chatURL: 'http://13.209.15.130:5000/chat',
	// 		});
	// 		setIsGeneratingAnswer(false);
			
	// 		if (response.data.body && response.data.body.reply) {
	// 			return response.data.body.reply;
	// 		} else {
	// 			throw new Error('Invalid server response');
	// 		}
	// 	} catch (error) {
	// 		console.error(`Error while calling chatbot API: ${error.message}`);
	// 		setIsGeneratingAnswer(false);
	// 		return '현재 서버 이용량이 많아 일시적으로 이용이 제한될 수 있습니다. 잠시 후에 다시 이용해주세요.';
	// 	}
	// };

	const getChatbotReply = async (userMessage, roomId) => {
		try{
			setIsGeneratingAnswer(true);
			const curToken = localStorage.getItem("token");
			const response = await axios.post(process.env.REACT_APP_NORM_CHAT_URL, {
				reqSentence: userMessage,
				kakaoId: roomId,
				token: curToken,
			});
			// console.log('API response at client: ', response);
			setIsGeneratingAnswer(false);
			
			if (response.data.body && response.data.body.text) {
				return response.data.body.text;
			} else {
				throw new Error('Invalid server response');
			}
		} catch (error) {
			console.error(`Error while calling chatbot API: ${error.message}`);
			setIsGeneratingAnswer(false);
			return '현재 서버 이용량이 많아 일시적으로 이용이 제한될 수 있습니다. 잠시 후에 다시 이용해주세요.';
		}
	};
    
    const submitMessage = async (message) => {
        if(message.trim()) {
			const chatbotReply = await getChatbotReply(message, roomId, userId);
			//inputRef.current.focus();
        } else {
            toast.error("메세지를 입력해주세요.")
        }
    }

	// Expert renew, 추후 General renew로 변경 필요
	const resetQueryCondition = async (roomId) => {
		try {
			const response = await axios.post(process.env.REACT_APP_EXPERT_CHAT_RENEW_URL, {
				kakaoId: roomId,
			});
			// console.log('renew res: ', response.data);
		} catch (error) {
			console.error(`Error while calling chatbot API: ${error.message}`);
		}
	}

    return (
		<ChatComp 
			submitMessage={submitMessage} 
			messages={messages} 
			setMessages={setMessages}
			myId={userId} 
			kakaoNick={kakaoNick}
			isGeneratingAnswer={isGeneratingAnswer} 
			fetchChatHistory={fetchChatHistory} 
			updateProductHistory={updateProductHistory} 
			getChatbotReply={getChatbotReply}
			productHistory={productHistory}
			chatData={chatData}
			resetQueryCondition={resetQueryCondition}
		/>
	);
}

export default ChatFunc;