import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './global.css';
import Main from './pages/main';
import ChatPageDemo from './pages/ChatPageDemo';

import Chat from './pages/chat';
import ChatFunc from './containers/ChatFunc';
import ChatFuncExp from './containers/ChatFuncExp';
import ChatDemo from './components/Chat/ChatDemo';
import Auth from './auth/auth';
import Page404 from './pages/404';

const App = () => {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/chat-room/:roomId/:userId" element={<Chat />}>
            <Route path="" element={<ChatFunc />} />
            <Route path="expert" element={<ChatFuncExp />} />
          </Route>
          <Route path="/demo" element={<ChatPageDemo />}>
            <Route path=":demoId">
              <Route path=':categoryId' element={<ChatDemo />} />
            </Route>
          </Route>
          <Route path="/auth" element={<Auth />} />
          <Route path='/404' element={<Page404 />} />
        </Routes>
    </BrowserRouter>
  )
}

ReactDOM.createRoot(document.getElementById('root')).render(<App />);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
