import React, { useEffect } from 'react';

import style from './ChatRoomEntry.module.css';
import topBanner from '../../images/TopBanner_2953_1772.png';
import buttonLogo1_wb from '../../images/kakaotalk-wb.png';
import buttonLogo2_wb from '../../images/google-play-logo-wb.png';
import buttonLogo3_wb from '../../images/accio-logo-wb.png';

import loadScript from 'load-script';
import { Kakao } from 'kakaojs';
import kakaoLogin from '../../images/kakao_sync_login_large_narrow.png';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import { Typography as TypographyComponent } from '@mui/material';

const ChatRoomEntry = ({enterChatRoom}) => {

	//COMM: Login with kakao
	useEffect(() => {
		loadScript('https://developers.kakao.com/sdk/js/kakao.js', () => {
			if (!window.Kakao.isInitialized()) {
				window.Kakao.init(process.env.REACT_APP_KAKAO_APP_KEY);
			}
		});
		displayToken();
	}, []);
	
	const displayToken = () => {
		const token = getCookie('authorize-access-token');
		if(token) {
			window.Kakao.Auth.setAccessToken(token);
			window.Kakao.Auth.getStatusInfo()
				.then(res => {
				if (res.status === 'connected') {
					document.getElementById('token-result').innerText
						= `login success, token: ${Kakao.Auth.getAccessToken()}`;
				}
			})
				.catch(err => {
				window.Kakao.Auth.setAccessToken(null);
			});
		}
	}
	const getCookie = name => {
		const parts = document.cookie.split(`${name}=`);
		if (parts.length === 2) { return parts[1].split(';')[0]; }
	}
	const loginWithKakao = () => {
		localStorage.setItem("token", "login");
		window.Kakao.Auth.authorize({
			redirectUri: process.env.REACT_APP_KAKAO_REDIRECT_URI,
		});
	}

	//COMM: Change page title
	useEffect(() => { document.title = '로그인하기'; }, []);

	return (
		<div className={style.ChatRoomEntry__wrapper}>

			<Box className={style.ChatRoomEntry__bannerTop}>
				<img src={topBanner} alt="혼자 고민하지 말고 물어보세요. 국내 최초 생성형 AI기반 상품 추천 플랫폼 아씨오" 
					style={{ width: '100%', height: '100%', objectFit: 'contain', objectPosition: 'center center' }} />
			</Box>

			<Button onClick={loginWithKakao} className={style.ChatRoomEntry__kakaobutton} disableRipple>
				<img src={kakaoLogin} alt="카카오로 시작하기" style={{ height: "4.8rem"}} />
			</Button>

			<Stack style={{display: "flex", alignItems: "center"}}>
				<div className={style.ChatRoomEntry__buttonGroup}>
					<a style={{width: "32%", textDecoration: "none"}} href="http://pf.kakao.com/_jFmHxj">
						<button className={style.ChatRoomEntry__button}>
							<span className={style.ChatRoomEntry__text}>카카오톡에서<br />아씨오<br />사용하기</span>
							<span className={style.ChatRoomEntry__logoWrap}>
								<img src={buttonLogo1_wb} width="26px" className={style.ChatRoomEntry__buttonLogo}/>
							</span>
						</button>
					</a>
					<a style={{width: "32%", textDecoration: "none"}} href="https://play.google.com/store/apps/details?id=com.accio.mobileapp">
						<button className={style.ChatRoomEntry__button}>
							<span className={style.ChatRoomEntry__text}>안드로이드<br />아씨오 앱<br />다운받기</span>
							<span className={style.ChatRoomEntry__logoWrap}>
								<img src={buttonLogo2_wb} width="24px" className={style.ChatRoomEntry__buttonLogo}/>
							</span>
						</button>
					</a>
					<a style={{width: "32%", textDecoration: "none"}} href="https://lgn6s5e4du1.typeform.com/to/ogZMmg2u">
						<button className={style.ChatRoomEntry__button}>
							<span className={style.ChatRoomEntry__text}>커머스에 특화된<br />생성형 AI<br />도입 문의하기</span>
							<span className={style.ChatRoomEntry__logoWrap}>
								<img src={buttonLogo3_wb} width="26px" className={style.ChatRoomEntry__buttonLogo}/>
							</span>
						</button>
					</a>
				</div>
				<Box className={style.ChatRoomEntry__bannerBottom} sx={{ marginTop: '1.2rem', marginBottom: '0rem', maxWidth: '100%', height: 'auto', }}>
					<TypographyComponent variant="caption" style={{ color: '#777777', display: 'inline-block' }}>
						<a href="https://waddlelab.notion.site/Update-History-92b5a9ba2e8c46f4b11bef80ee3c7f77" style={{ color: "#777777" }} target="_blank" rel="noopener noreferrer">ACCIO 1.3.0 Version</a>
						{' '}
						<a href="https://waddlelab.notion.site/G-Expert-Release-Notes-19f348ea75494df89482ca3375567316" style={{ color: "#777777" }} target="_blank" rel="noopener noreferrer">G.Expert 1.0.0 Version</a>
						&nbsp;Made by WADDLE Corporation
					</TypographyComponent>
				</Box>
				<Box className={style.ChatRoomEntry__bannerBottom} sx={{ marginBottom: '9.6rem', maxWidth: '100%', height: 'auto', }}>
					<TypographyComponent variant="caption" style={{ color: '#777777', display: 'inline-block' }}>
						<a href="https://waddlelab.notion.site/ACCIO-c8f9681c6b4f4dbba782556c36aa8b61" style={{ color: "#777777" }} target="_blank" rel="noopener noreferrer">이용약관</a>
						{' '}
						<a href="https://waddlelab.notion.site/ACCIO-c73e35092b7f4b65bf7ef029974775a8" style={{ color: "#777777" }} target="_blank" rel="noopener noreferrer">개인정보처리방침</a>
					</TypographyComponent>
				</Box>
			</Stack>
			
		</div>
	);
}

export default ChatRoomEntry;