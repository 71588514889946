export const chatData = {
	"example1_label": [
		'무라벨 생수 2L 12개 찾아줘',
		'적당한 가격대의 커피포트 알아서 추천해줘',
		'무소음 버티컬 마우스 2만원 정도 가격으로 찾아줘',
		'물걸레 청소포 중형 30매 찾아줘',
		'공룡 캐릭터 에어팟 프로 케이스 찾아줘',
	],
	"example1_text": [
		'무라벨 생수 2L 12개 찾아줘',
		'적당한 가격대의 커피포트 알아서 추천해줘',
		'무소음 버티컬 마우스 2만원 정도 가격으로 찾아줘',
		'물걸레 청소포 중형 30매 찾아줘',
		'공룡 캐릭터 에어팟 프로 케이스 찾아줘',
	],
	"example2_label": [
		'회사에서 사용할 대용량 텀블러 추천해줘',
		'미세먼지가 심한 날 쓸 KF94 마스크 찾아줘',
		'층간소음 방지하는 슬리퍼 추천해줘',
		'자취방 분위기를 화사하게 바꿔 줄 조명이 있을까?',
		'다이어트 중인데, 칼로리가 높지 않은 에너지바 추천해줘',
	],
	"example2_text": [
		'회사에서 사용할 대용량 텀블러 추천해줘',
		'미세먼지가 심한 날 쓸 KF94 마스크 찾아줘',
		'층간소음 방지하는 슬리퍼 추천해줘',
		'자취방 분위기를 화사하게 바꿔 줄 조명이 있을까?',
		'다이어트 중인데, 칼로리가 높지 않은 에너지바 추천해줘',
	],
	"example3_label": [
		'그거 뭐라고 하더라? 아몬드보다 큰 견과류',
		'모니터를 책상에 고정시키는 장치를 뭐라고 부르지?',
		'달래전 레시피 알려줄 수 있어?',
		'조거팬츠랑 잘 어울리는 요즘 유행하는 신발 찾아줘',
		'2023 패션 트렌드 알려줘',
		'황사철인데, 기관지에 좋은 음식은 어떤 게 있으려나?',
		'다음주 서울 날씨에 어울리는 외투는 뭐야?',
	],
	"example3_text": [
		'그거 뭐라고 하더라? 아몬드보다 큰 견과류',
		'모니터를 책상에 고정시키는 장치를 뭐라고 부르지?',
		'/검색 달래전 레시피 알려줄 수 있어?',
		'/검색 조거팬츠랑 잘 어울리는 요즘 유행하는 신발 찾아줘',
		'/검색 2023 패션 트렌드 알려줘',
		'/검색 황사철인데, 기관지에 좋은 음식은 어떤 게 있으려나?',
		'/검색 다음주 서울 날씨에 어울리는 외투는 뭐야?',
	]
}

export const chatDataDrink = {
	"example1_label": [
		'콜라랑 타 먹기 좋은 위스키 알려줘',
		'맛이 부드러운 데일리 위스키 추천해줘',
		'바닐라나 꿀 향이 진한 버번 위스키 찾아줘',
		'아일라 위스키 중 괜찮은거 알려줘',
		'일본 위스키 추천해줘',
	],
	"example1_text": [
		'콜라랑 타 먹기 좋은 위스키 알려줘',
		'맛이 부드러운 데일리 위스키 추천해줘',
		'바닐라나 꿀 향이 진한 버번 위스키 찾아줘',
		'아일라 위스키 중 괜찮은거 알려줘',
		'일본 위스키 추천해줘',
	],
	"example2_label": [
		'소고기랑 같이 마실 와인 추천해줘!',
		'달달한 스파클링 와인 찾아줘',
		'오늘 피자 먹을건데 같이 편하게 마실 데일리 와인으로 뭐가 좋을까?',
		'요즘 인기 많은 와인들 어떤게 있는지 보여줘',
	],
	"example2_text": [
		'소고기랑 같이 마실 와인 추천해줘!',
		'달달한 스파클링 와인 찾아줘',
		'오늘 피자 먹을건데 같이 편하게 마실 데일리 와인으로 뭐가 좋을까?',
		'요즘 인기 많은 와인들 어떤게 있는지 보여줘',
	],
	"example3_label": [
		'맥캘란 찾아줘',
		'술 좋아하는 친구에게 줄 선물 추천해줘',
		'연말에 들고 가면 부모님이 좋아하실 위스키 추천해줘',
		'크리스마스 선물로 좋은 5만에서 10만원 정도 되는 와인 추천해줘',
	],
	"example3_text": [
		'맥캘란 찾아줘',
		'술 좋아하는 친구에게 줄 선물 추천해줘',
		'연말에 들고 가면 부모님이 좋아하실 위스키 추천해줘',
		'크리스마스 선물로 좋은 5만에서 10만원 정도 되는 와인 추천해줘',
	]
}

export const chatDataBook = {
	"example1_label": [
		'재미있는 판타지 소설 추천해줘',
		'고전 소설을 좋아하는데 추천해줄 수 있어?',
		'오펜하이머 영화를 감명 깊게 봤어. 책도 있어?',
		'히가시노 게이고의 작품 추천해줘 ',
	],
	"example1_text": [
		'재미있는 판타지 소설 추천해줘',
		'고전 소설을 좋아하는데 추천해줄 수 있어?',
		'오펜하이머 영화를 감명 깊게 봤어. 책도 있어?',
		'히가시노 게이고의 작품 추천해줘 ',
	],
	"example2_label": [
		'출퇴근 할 때 가볍게 읽을 만 한 책 추천해줘',
		'미스터리 추리소설 하나 추천해줘!',
		'감동적인 내용의 한국 소설 찾아줘',
		'현대 사회에 대해서 생각해 볼 수 있는 책 추천해줘',
	],
	"example2_text": [
		'출퇴근 할 때 가볍게 읽을 만 한 책 추천해줘',
		'미스터리 추리소설 하나 추천해줘!',
		'감동적인 내용의 한국 소설 찾아줘',
		'현대 사회에 대해서 생각해 볼 수 있는 책 추천해줘',
	],
	"example3_label": [
		'오늘처럼 비가 오는 날에는 어떤 책을 읽는 것이 좋을까?',
		'요즘 사람들이 가장 많이 읽는 책 중에 추천해줘',
		'휴가 때 따스한 분위기의 책을 읽어 보고 싶어',
		'소소한 일상이 담긴 에세이를 읽으면서 힐링하고 싶어',
	],
	"example3_text": [
		'오늘처럼 비가 오는 날에는 어떤 책을 읽는 것이 좋을까?',
		'요즘 사람들이 가장 많이 읽는 책 중에 추천해줘',
		'휴가 때 따스한 분위기의 책을 읽어 보고 싶어',
		'소소한 일상이 담긴 에세이를 읽으면서 힐링하고 싶어',
	]
}