import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import style from './Chat.module.css';
import styleMsg from '../Message/Message.module.css';

import { FiSend } from 'react-icons/fi';
import CircularProgress from '@mui/material/CircularProgress';

import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import Message from '../Message/Message';
import {
    getChatbotReply,
    resetQueryCondition,
    randomText,
    loadChatHistory,
} from '../../containers/ChatFuncDemo';

const ChatFieldDemo = (props) => {
    let [ messages, setMessages ] = useState([]);
    let [ isGeneratingAnswer, setIsGeneratingAnswer ] = useState(false);
    let [ examLabelArr, setExamLabelArr ] = useState([]);
    let [ examTextArr, setExamTextArr ] = useState([]);
    let [ feedbackStat, setFeedbackStat ] = useState(false);
    let [ resetBtn, setResetBtn ] = useState(false);
    let [ visibleInfoModal, setVisibleInfoModal ] = useState(false);
    let [ loaded, setLoaded ] = useState(false);

	const roomId = useParams().demoId;
    const category = useParams().categoryId;
	const userId = "user"+roomId;
    const myId = roomId;
    const kakaoNick = userId;

    const [isMobileDevice, setIsMobileDevice] = useState(false);
    const [isMyMessage, setIsMyMessage] = useState(false);
	const isExpert = (kakaoNick === "guest");
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isAOS = /Android/i.test(navigator.userAgent);

    //COMM: Check whether the device is mobile or not
    useEffect(() => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        setIsMobileDevice(isMobile);
    }, []);

    // API request for query condition at server
    useEffect(() => {
        // resetQueryCondition(roomId);
        updateChatHistory(roomId);
    }, []);

    const updateChatHistory = async (roomId) => {
        const loadMessages = await loadChatHistory();
        if (loadMessages) setLoaded(true);
        loadMessages?.chat.map((data, idx) => {
            if (data.content.includes("새로운 대화")) return
            if (data.role === 'user') {
                setMessages((prev) => prev.concat({userId: roomId, message: data.content}));
            } else {
                setMessages((prev) => prev.concat({userId: 'Accio', message: data.content, example: data?.example, product: data?.product}));
            }
        })
    }

    const messagesEndRef = useRef(null);
    const [input, setInput] = useState('');

    const updateIsGeneratingAnswer = (newValue) => {
        setIsGeneratingAnswer(newValue);
    };

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (isGeneratingAnswer) {
                return;
            }
            onClick();
        }
    };
    const onClick = async () => {
        if (isGeneratingAnswer || kakaoNick === '') return;
        if (input === '') return;
        let message = [{userId: roomId, message: input}];
        setMessages((prev) => prev.concat(message));
        setInput('');
        if (feedbackStat) {
            const chatbotReply = await getChatbotReply(input, updateIsGeneratingAnswer, feedbackStat);
            setMessages((prev) => prev.concat({userId: 'Accio', message: chatbotReply.text}));
            setFeedbackStat(false);
            return;
        } else {
            const chatbotReply = await getChatbotReply(input, updateIsGeneratingAnswer);
            setMessages((prev) => prev.concat({userId: 'Accio', message: chatbotReply.text, example: chatbotReply?.example, product: chatbotReply?.product}));
        }
    };
    const onChange = (e) => {
        e.preventDefault();
        setInput(e.currentTarget.value);
    };

    //COMM: Auto scroll to bottom when new message is present
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
    };

    useEffect(() => {
        if (messages.length === 1) {
            document.getElementById('ChatField').scrollTo(0, 0);
        } else {
            scrollToBottom();
        }
    }, [messages, resetBtn]);

    const handleButtonClick_send = async (text) => {
        let message = [{userId: roomId, message: text}];
        setMessages((prev) => prev.concat(message));
        setInput('');
        const chatbotReply = await getChatbotReply(text, updateIsGeneratingAnswer);
        setMessages((prev) => prev.concat({userId: 'Accio', message: chatbotReply.text, example: chatbotReply?.example, product: chatbotReply?.product}));
    };

    const handleInfoModalIn = (e) => {
        setVisibleInfoModal(true);
    }

    const handleInfoModalOut = (e) => {
        setVisibleInfoModal(false);
    }

    //COMM: Move VoiceOver focus to HEADER
    useEffect(() => {
        setTimeout(() => {
            const header = document.getElementById('HEADER');
            if (header) {
                header.tabIndex = -1;
                header.focus();
            }
        }, 250);
    }, []);

    //COMM: Change page title
    document.title = '나만의 AI 쇼핑 메이트 아씨오';

    const [randomGreeting, setRandomGreeting] = useState('');
    //COMM: Set greeting text
    useEffect(() => {
		// console.log('what kakaonick? ', kakaoNick);
		const greeting_text1 = `안녕하세요 😀 오늘은 어떤 상품을 찾고 계세요? 무엇이든 물어봐 주세요!`;
		const greeting_text2 = `안녕하세요 🧐 어떤 상품을 찾아드릴까요? 아씨오가 추천해드릴게요.`;

        const greeting_text = [greeting_text1, greeting_text2];
        const greeting_index = Math.floor(Math.random() * greeting_text.length);
        setRandomGreeting(greeting_text[greeting_index]);
    }, [kakaoNick]);

    // Top-toggle switching style
    useEffect(() => {     
        randomText(setExamLabelArr, setExamTextArr, category);
	},[category])
	
    useEffect(() => {
        if (messages[messages.length - 1]?.userId === 'Accio' && !feedbackStat && !messages[messages.length - 1].product) {
            setResetBtn(true)
        } else {
            setResetBtn(false)
        }
    }, [messages])

    return (
        <div className={style.Chat__chatWrap} style={{height: (isIOS && "calc(100% - 120px)")}}>
            <div className={style.Chat__chatTopWrap}>
                {messages.length === 0 && !isMobileDevice && (
                    <div className={`${style.Chat__main} ${style.Chat__flex}`}>
                        <div className={style.Util__w100mw900}>
                            <div className={style.Chat__greetingWrap}>
                                <div
                                    className={style.Chat__icon}
                                    role="heading"
                                    aria-level="3"
                                />
                                <div id="HEADER" className={style.Chat__greetingContent}>
                                    <p className={`${style.Util__bold} ${style.Util__textStart}`}>
                                        {randomGreeting}
                                    </p>
                                </div>
                            </div>
                            <div
                                direction="row"
                                className={style.Chat__exampleWrap}
                                role="region"
                                aria-label="추천 예시"
                            >
                                <div
                                    onMouseOver={(e) => { handleInfoModalIn(e) }}
                                    onMouseLeave={(e) => { handleInfoModalOut(e) }}
                                >
                                    <div style={{ position: 'relative' }}>
                                        {
                                            visibleInfoModal ?
                                                <div className={style.Chat__infoModal}>
                                                    아씨오와의 대화가 처음이신가요? <br /><br />
                                                    추천 드리는 예시 문구를 클릭해 대화를 시작하실 수 있어요!
                                                </div> :
                                                null
                                        }
                                    </div>
                                    <div
                                        className={style.Chat__iconExam}
                                    >
                                        {/* <img src={bulbIcon} /> */}
                                        <p
                                            onMouseOver={(e) => e.stopPropagation()}
                                            onMouseLeave={(e) => e.stopPropagation()}
                                        >
                                            ?
                                        </p>
                                    </div>
                                </div>
                                {
                                    examLabelArr.map((data, idx) => {
                                        return (
                                            <button
                                                key={idx}
                                                onClick={() => handleButtonClick_send(examTextArr[idx])}
                                                className={style.Chat__example}
                                                aria-label={examTextArr[idx]}
                                                sx={idx === 2 ? { marginLeft: 1.5, marginRight: 1.5 } : undefined}
                                            >
                                                <p className={`${style.Util__bold} ${style.Util__textStart}`}>
                                                    {data}
                                                </p>
                                            </button>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                )}
                {messages.length === 0 && isMobileDevice && (
                    <div className={style.Chat__mainmd}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    height: 'auto',
                                    flex: 1,
                                    borderRadius: '10px',
                                    paddingBottom: '16px',
                                }}
                            >
                                <div
                                    className={`${isExpert ? style.Chat__iconExp : style.Chat__icon}`}
                                    style={{ objectFit: 'cover' }}
                                    role="heading"
                                    aria-level="3"
                                />
                                <div
                                    id="HEADER"
                                    className={style.Chat__greeting}
                                >
                                    <p className={`${style.Util__bold} ${style.Util__textStart}`}>
                                        {randomGreeting}
                                    </p>
                                </div>
                            </Box>

                            <Stack
                                direction="column"
                                sx={{ flex: '0 0 auto', flexWrap: 'wrap' }}
                                role="region"
                                aria-label="추천 예시"
                            >
                                <button
                                    onClick={() => handleButtonClick_send(examTextArr[0])}
                                    className={style.Chat__example_mobile}
                                    aria-label={examTextArr[0]}
                                >
                                    <p className={`${style.Util__bold} ${style.Util__textStart}`}>
                                        {examLabelArr[0]}
                                    </p>
                                </button>
                                <button
                                    onClick={() => handleButtonClick_send(examTextArr[1])}
                                    className={style.Chat__example_mobile}
                                    aria-label={examTextArr[1]}
                                >
                                    <p className={`${style.Util__bold} ${style.Util__textStart}`}>
                                        {examLabelArr[1]}
                                    </p>
                                </button>
                                <button
                                    onClick={() => handleButtonClick_send(examTextArr[2])}
                                    className={style.Chat__example_mobile}
                                    aria-label={examTextArr[2]}
                                >
                                    <p className={`${style.Util__bold} ${style.Util__textStart}`}>
                                        {examLabelArr[2]}
                                    </p>
                                </button>
                            </Stack>
                    </div>
                )}
                {messages.length !== 0 && (
                    <div style={{ width: '100%', height: '100%' }}>
                        <div
                            id="ChatField"
                            className={`${isMobileDevice ? style.Chat__mainmd : style.Chat__mainDemo} ${
                                style.Chat__flex
                            }`}
                        >
                            <div
                                className={style.Chat__list}
                                role="region"
                                aria-label="대화"
                                aria-live="polite"
                            >
                                <Message userId="Accio" text={randomGreeting}>
                                    {' '}
                                </Message>
                                {messages?.map(({ userId, message, example, product }, index) => {
                                    return (
                                        <Message
                                            key={`${userId}_${index}`}
                                            userId={userId}
                                            myId={myId}
                                            text={message}
                                            example={example}
                                            product={product}
                                            handleButtonClick_send={handleButtonClick_send}
                                            setMessages={setMessages}
                                            setFeedbackStat={setFeedbackStat}
                                            setResetBtn={setResetBtn}
                                        >
                                            {' '}
                                        </Message>
                                    );
                                })}
                                {
                                    isGeneratingAnswer &&
                                    <li className={styleMsg.Message__list} style={{ width: 'fit-content' }}>
                                        <span
                                            className={styleMsg.Message__icon}
                                            role="heading"
                                            aria-level="3"
                                        />
                                        <div className={styleMsg.Message__content}>
                                            <div className={styleMsg.Message__text}>
                                                <div className={style.Chat__progressWrap}>
                                                    <div className={style.Chat__progress}></div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className={style.Chat__progressTextWrap}> */}
                                        <div className={style.Chat__progressText}>
                                            <span>답</span>
                                            <span>변</span>
                                            <span>하</span>
                                            <span>는</span>
                                            <span>중</span>
                                        </div>
                                        {/* </div> */}
                                    </li>
                                }
                                {/* <div style={{paddingBottom: "8px"}}>
                                    <div 
                                        className={style.Chat__bottombtn} 
                                        style={{display: (resetBtn ? 'block' : 'none')}} 
                                        data-value="새로운 대화하기"
                                        onClick={(e) => {handleButtonClick_send(e.target.dataset.value)}} >
                                            😊 새로운 대화하기
                                    </div>
                                </div> */}
                                <div ref={messagesEndRef} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {/* <div className={isMobileDevice ? style.Chat__backmd : null}></div> */}
            <div className={isMobileDevice ? style.Chat__bottommd : style.Chat__bottom}>
                <div className={isMobileDevice ? style.Chat__inputmd : style.Chat__input}>
                    <div className={!isGeneratingAnswer ? style.Chat__input1 : style.Chat__input1__block} >
                        <input
                            className={style.Chat__inputfield1}
                            onChange={onChange}
                            onKeyUp={handleKeyPress}
                            type='text'
                            placeholder={!isGeneratingAnswer ? '점원에게 말하듯 편하게 물어보세요' : '답변 생성 중에는 입력하실 수 없어요'}
                            value={input}
                            aria-level='메시지'
                        />
                        <div className={style.Chat__inputButtonWrap}>
                            {
                                isGeneratingAnswer ?
                                    <CircularProgress
                                        id="LOADING"
                                        size={20}
                                        className={style.Chat__loading}
                                        aria-label="로딩 중"
                                        role="alert"
                                    /> :
                                    <button
                                        className={style.Chat__inputButton}
                                        onClick={onClick}
                                        aria-level='전송하기'
                                    >
                                        <FiSend />
                                    </button>
                            }
                        </div>
                    </div>
                </div>
                <div className={style.Chat__footer} ><a href='https://www.waddlelab.com'>powered by WADDLE</a></div>
            </div>
        </div>
    );
};

export default ChatFieldDemo