import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

//COMM: import Modal
import Modal from 'react-modal';
import ChatFieldDemo from './ChatFieldDemo.jsx'
import Onboarding from './Onboarding.jsx'
import { randomText } from '../../containers/ChatFuncDemo';
import { ThemeProvider, createTheme } from '@mui/material';

Modal.setAppElement('#root');

//COMM: Main Component that contains messages, textfield, etc.

const ChatDemo = (props) => {
	const navigate = useNavigate();
	// const { roomId, userId } = useParams();
	const roomId = useParams().demoId;

	const userId = "user"+roomId;
    const [onboardEnd, setOnboardEnd] = useState(false);

	//COMM: Create theme for textfield
    const theme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#154CCB' },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#154CCB',
                        },
                        borderRadius: '10px',
                    },
                },
            },
            typography: {
                // Tell Material UI what the font-size on the html element is.
                htmlFontSize: 10,
            },
        },
    });
    const {examLabelArr, examTextArr} = randomText();
	
    return (
        <ThemeProvider theme={theme}>
            {/* <Onboarding setOnboardEnd={setOnboardEnd}/> */}
            <ChatFieldDemo 
                onboardEnd={onboardEnd} 
            />
        </ThemeProvider>
    );
};

export default ChatDemo;