import { toast } from "react-toastify";
import axios from 'axios';
import { chatDataBook, chatDataDrink } from "../data/chatData";

import starFill from '../images/star_filled.svg';
import starEmpty from '../images/star_empty.svg';
import starHalf from '../images/star_half.svg';

sessionStorage.setItem('token', 'login');

export const fetchChatHistory = async (roomId, userId, setMessages) => {
	try {
		const response = await axios.post(process.env.REACT_APP_NORM_CHAT_LOAD_URL, {
			kakaoId: roomId,
			loginType: userId,
		});
		if (response.data.body) {
			const formattedChatHistory = response.data.body.res.map((chat, index) => {
				return {
					userId: chat.role === 'user' ? userId : 'Accio',
					message: chat.content,
				};
			});
			setMessages(formattedChatHistory);
		} else {
			throw new Error('Invalid server response');
		}
	} catch (error) {
		console.error(`Error while fetching chat history: ${error.message}`);
	}
};

export const fetchProductHistory = async (roomId, userId, isNewSearch) => {
	try {
		// console.log("userNick: ", roomId);
		// console.log("userPW: ", userId);
		// console.log("isNewSearch: ", isNewSearch);
		const response = await axios.post(process.env.REACT_APP_NORM_PRODUCT_LOAD_URL, {
			kakaoId: roomId,
			loginType: userId,
			isNewSearch: isNewSearch,
		});
		// console.log('load-product res: ', response);
		if (response.data.body) {
			const formattedProductHistory = response.data.body.map((product, index) => {
				return {
					date: product.Date,
					productName: product.ProductName,
					productUrl: product.ProductUrl,
				};
			});
			return formattedProductHistory;
		} else {
			throw new Error('Invalid server response');
		}
	} catch (error) {
		console.error(`Error while fetching product history: ${error.message}`);
	}
};

export const updateProductHistory = async (setProductHistory) => {
	setProductHistory(fetchProductHistory(1));
};

export const getChatbotReply = async function (userMessage, updateIsGeneratingAnswer, feedbackStat) {
	const requestURL = (feedbackStat ? process.env.REACT_APP_CUSTOM_DLST_CHAT_FB_URL : process.env.REACT_APP_CUSTOM_DLST_CHAT_URL );
	const userId = sessionStorage.getItem('auth');
	const udid = sessionStorage.getItem('udid');
	try {
		updateIsGeneratingAnswer(true);
		const response = await axios.post(requestURL,
			{
				userId: userId,
				clientId: "dlst",
				channelId: "webview",
				requestMessage: userMessage,
			}, {
			headers: {
				udid: udid,
				'x-api-key': process.env.REACT_APP_X_API_KEY_DLST,
				'Content-type': 'application/json',
			},
		}
		);
		// console.log('API response at client: ', response);
		updateIsGeneratingAnswer(false);

		return response.data.data
	} catch (error) {
		updateIsGeneratingAnswer(false);
		console.error(`Error while calling chatbot API: ${error.message}`);
		return '현재 서버 이용량이 많아 일시적으로 이용이 제한될 수 있습니다. 잠시 후에 다시 이용해주세요.';
	}
};

export const submitMessage = async (message, roomId, setIsGeneratingAnswer) => {
	if (message.trim()) {
		const chatbotReply = await getChatbotReply(message, roomId, setIsGeneratingAnswer);
		//inputRef.current.focus();
	} else {
		toast.error("메세지를 입력해주세요.")
	}
}

export const resetQueryCondition = async (roomId) => {
	const requestURL = process.env.REACT_APP_CUSTOM_DLST_CHAT_RENEW_URL;
	const userId = sessionStorage.getItem('auth');
	if(userId === undefined) return;
	try {
		const response = await axios.post(requestURL, {
			clientId: 'dlst',
			userId: userId,
		}, {
			headers: {
				'x-api-key': process.env.REACT_APP_X_API_KEY_DLST,
			},
		});
		// console.log('renew res: ', response.data);
	} catch (error) {
		console.error(`Error while calling chatbot API: ${error.message}`);
	}
}

export const loadChatHistory = async () => {
	const requestURL = process.env.REACT_APP_CUSTOM_DLST_CHAT_LOAD_URL;
	const userId = sessionStorage.getItem('auth');
	if (userId === undefined) return;
	try {
		const response = await axios.post(requestURL, {
			clientId: 'dlst',
			userId: userId,
			channelId: "webview",
		}, {
			headers: {
				'x-api-key': process.env.REACT_APP_X_API_KEY_DLST,
			},
		});
		return response.data.data;
	} catch (error) {
		console.error(`Error while calling load API: ${error.message}`)
	}
}

export const randomText = async (setExamLabelArr, setExamTextArr, category) => {
	if (setExamLabelArr === undefined || setExamTextArr === undefined) return;
	let categoryData = chatDataDrink;
	const randomIdxArr = [
		Math.floor(Math.random() * categoryData.example1_label.length),
		Math.floor(Math.random() * categoryData.example2_label.length),
		Math.floor(Math.random() * categoryData.example3_label.length),
	];
	setExamLabelArr([
		categoryData.example1_label[randomIdxArr[0]],
		categoryData.example2_label[randomIdxArr[1]],
		categoryData.example3_label[randomIdxArr[2]]
	]);
	setExamTextArr([
		categoryData.example1_text[randomIdxArr[0]],
		categoryData.example2_text[randomIdxArr[1]],
		categoryData.example3_text[randomIdxArr[2]],
	]);
}

export const genStars = (rate) => {
	const size = '14px';
	let stars = [];
	for (let i=0; i<5; i++) {
		if (rate - i >= 1) stars.push(starFill)
		else if (rate - i < 1 && rate - i > 0) stars.push(starHalf)
		else stars.push(starEmpty)
	}

	return (

		<div style={{display: 'flex', height: '100%', alignItems: 'flex-end'}}>		
			<img src={stars[0]} width={size} height={size}/>
			<img src={stars[1]} width={size} height={size}/>
			<img src={stars[2]} width={size} height={size}/>
			<img src={stars[3]} width={size} height={size}/>
			<img src={stars[4]} width={size} height={size}/>
		</div>
	)
}

export const addComma = (num) => {
	const parts = num.toString().split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return parts.join(".");
}